import { cloneDeep, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import RoutePaths from '@/utils/routes';

export enum KEY_CACHED_DATA {
  MY_SUB_SETTING = 'MY_SUB_SETTING',
  MY_SUB = 'MY_SUB',
  CREDIT_SETTING = 'CREDIT_SETTING',
  MY_CREDIT = 'MY_CREDIT',
  USER_ASSETS = 'USER_ASSETS',
  CAMPAIGN_TYPE_LIST = 'CAMPAIGN_TYPE_LIST',
}
export const useCachedPromise = () => {
  const stateData = useSelector((state: RootState) => ({
    [KEY_CACHED_DATA.CREDIT_SETTING]: { result: state.credit.creditSetting },
    [KEY_CACHED_DATA.MY_CREDIT]: { result: state.credit.myCredit },
    [KEY_CACHED_DATA.MY_SUB_SETTING]: { result: state.homeCredit.mySetting },
    [KEY_CACHED_DATA.MY_SUB]: { result: state.homeCredit.mySub },
    // [KEY_CACHED_DATA.USER_ASSETS]: { result: state.user.userAssets },
  }));
  const cachedData = cloneDeep(stateData);
  const dispatch = useDispatch();
  const clearCache = useCallback(() => {
    for (const key in cachedData) {
      delete cachedData[key];
    }
  }, []);

  const getCachedPromise = useCallback(
    async (key, promiseFunc, apiParams = undefined, shouldCache = true) => {
      if (!shouldCache || window.location.pathname.includes(`${RoutePaths.LOGIN}`)) {
        clearCache();
        return promiseFunc();
      }

      if (cachedData[key] && cachedData[key]?.result?.isPersistState) {
        return Promise.resolve(cachedData[key]?.result);
      }

      try {
        const promise = apiParams ? await promiseFunc(apiParams) : await promiseFunc();
        cachedData[key] = { promise };
        const result = await promise;
        if (isEmpty(result)) {
          cachedData[key].result = result;
          return result;
        }
        const updatedResult = { ...result, isPersistState: true };
        cachedData[key].result = updatedResult;
        return updatedResult;
      } catch (error) {
        clearCache(); // Clear the cache if there's an error
        throw error;
      }
    },
    [cachedData, clearCache, dispatch],
  );

  return { getCachedPromise };
};
