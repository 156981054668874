import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';
import CampaignService from '@/services/campaign';
import UserService from '@/services/user';
import { forceLogout } from '@/utils/auth';
import RoutePaths from '@/utils/routes';
import { useMemo } from 'react';

export const useUserProfile = () => {
  const getUserProfile = async () => {
    try {
      return await UserService.getMyProfile();
    } catch (error) {
      forceLogout();
    }
  };

  return getUserProfile;
};

export const useConstantValue = () => {
  let pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  if (pathname !== '/') {
    pathname = pathname.replace(/\/$/, '');
  }
  const isFetchData = useMemo(
    () =>
      [
        RoutePaths.SETTINGS,
        RoutePaths.AGENT_LOOKUP,
        RoutePaths.CAMPAIGNS,
        RoutePaths.MANAGER,
        RoutePaths.MAILING_LISTS,
        RoutePaths.MY_REPORT,
        RoutePaths.PROSPECT_LOOKUP,
        RoutePaths.ON_BOARDING,
        RoutePaths.CREATE_MAILING_MANAGER,
        RoutePaths.MAILING_LISTS_CREATE,
        RoutePaths.DRIP_CAMPAIGNS,
        RoutePaths.SMART_ACTIONS,
        RoutePaths.DRIP_CAMPAIGNS_CREATE,
        RoutePaths.BROKER_CAMPAIGN_SENDING,
        RoutePaths.BROKER_MY_AGENTS,
      ].some((el) => pathname.includes(el)),
    [],
  );
  const { data: constantValue } = useGetConstantValueQuery({
    enabled: isFetchData,
  });

  return constantValue;
};

export const useCampaignTypeList = () => {
  const getCampaignTypeList = async () => {
    let pathname = typeof window !== 'undefined' ? window.location.pathname : '';
    if (pathname !== '/') {
      pathname = pathname.replace(/\/$/, '');
    }
    try {
      if (
        [
          RoutePaths.CAMPAIGNS,
          RoutePaths.DRIP_CAMPAIGNS,
          RoutePaths.SMART_ACTIONS,
          RoutePaths.DRIP_CAMPAIGNS_CREATE,
          RoutePaths.BROKER_CAMPAIGN_SENDING,
          RoutePaths.MYLISTING_CAMPAIGN_SENDING,
        ].some((el) => pathname.includes(el))
      )
        return await CampaignService.listCampaignType();
    } catch (error) {
      return [];
    }
  };

  return getCampaignTypeList;
};
