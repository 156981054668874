import { AlertTextInfo } from '@/components/alert/NotificationAlert';
import { DuplicatedMailingListEventData } from '@/models/DuplicatedMailingListEventData';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import { color } from '@/styles/theme';
import RoutePaths from '@/utils/routes';
import { MailingListStatusEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'gatsby';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGlobalSocketEventData = () => {
  const dispatch = useDispatch();

  const duplicatedMLEventData = useSelector((state: RootState) => state.mailing.duplicatedMLEventData);

  useEffect(() => {
    if (!duplicatedMLEventData) return;

    const data = duplicatedMLEventData as DuplicatedMailingListEventData;

    const isCompleted = data.mailing_list_status === MailingListStatusEnum.Completed;
    AlertTextInfo(
      <p>
        We&apos;ve detected that <span style={{ color: color.PINK, fontWeight: 700 }}>{data.duplicated_count}</span>{' '}
        address(es) are duplicated across multiple current mailing lists. To avoid redundant data and unnecessary home
        credit expenses, we will retain these addresses only in their original mailing lists{' '}
        {isCompleted ? (
          <Link
            style={{ color: color.PINK, fontWeight: 700 }}
            to={`${RoutePaths.MAILING_LISTS}/${data.mailing_list_id}?type=${data.mailing_list_type}`}
          >
            {data.mailing_list_name}
          </Link>
        ) : (
          <Tooltip title={!data.has_mailings ? 'No Mailing' : 'Fetching...'}>
            <span style={{ color: color.PINK, fontWeight: 700 }}>{data.mailing_list_name}</span>
          </Tooltip>
        )}
        .
      </p>,
      false,
      10,
      true,
    );
    dispatch({ type: MailingAction.LISTEN_DUPLICATED_MAILING_LIST_DATA, payload: null });
  }, [duplicatedMLEventData]);
};

export default useGlobalSocketEventData;
