import { FilterTagTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { MySub } from '@goldfishcode/first-team-real-estate-sdk/libs/api/subscription/models';
import { User } from '@goldfishcode/first-team-real-estate-sdk/libs/api/user/models';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KEY_CACHED_DATA, useCachedPromise } from '@/hooks/useCachedPromise';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import { useQueryParam } from '@/hooks/useQueryParam';
import AppAction from '@/reducers/app/action';
import AuthAction from '@/reducers/auth/action';
import CampaignAction from '@/reducers/campaign/actions';
import CreditAction from '@/reducers/credit/action';
import HomeLimitAction from '@/reducers/home-credit/action';
import HomeAction from '@/reducers/home/action';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import CampaignService from '@/services/campaign';
import CreditService from '@/services/credit';
import MailingService from '@/services/mailing';
import ToolsService from '@/services/tools';
import { forceLogout } from '@/utils/auth';
import { StorageKeys, UserType } from '@/utils/enum';
import RoutePaths from '@/utils/routes';
import { generateDefaultImageForVariables, getAllImageVariables } from '@/utils/utils';
import { LoadingPage } from '../loading/loadingBase';
import { useCampaignTypeList, useConstantValue, useUserProfile } from '@/hooks/dataFetchingHooks';
import { SettingTabsKey, SmartActionTabsKey } from '@/models';
import { useSubscriptionSubSetting } from '@/queries/subscription/subSetting';
import OverviewService from '@/services/overview';
import { useCampaignManagerStore } from '../dashboard/hooks/useCampaignManager';
import { useShallow } from 'zustand/react/shallow';
import { SlipStreamAgent } from '@goldfishcode/first-team-real-estate-sdk/libs/api/slipstream/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { limit_agent } from '@/utils/constant';
import useGlobalSocketEventData from '@/hooks/useGlobalSocketEventData';
import { MySwal } from '../alert/SweetAlert';

// const BROKER_ROUTES = [RoutePaths.DASHBOARD, RoutePaths.BROKER_MY_AGENTS, RoutePaths.SETTINGS, RoutePaths.LOGOUT];

const PrivateProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const userProfile = useUserProfile();
  useConstantValue();

  const campaignTypeList = useCampaignTypeList();
  const { refetch: getMySub } = useSubscriptionSubSetting({
    enabled: false,
  });
  const { setListAgent, setIsLoadingAgent } = useCampaignManagerStore(
    useShallow((state) => ({
      setListAgent: state.setListAgent,
      setIsLoadingAgent: state.setIsLoadingAgent,
    })),
  );
  const { ssid } = useQueryParam<{ ssid: string; tab?: string }>();
  const { getCachedPromise } = useCachedPromise();
  const [isLoading, setIsLoading] = useState(true);
  const { checkDisable } = useDisableBySubscription();
  const cachedData = useSelector((state: RootState) => state.auth.myProfile);
  const cachedAgent = useSelector((state: RootState) => state.home.userAgent?.id);

  useGlobalSocketEventData();

  let pathname = typeof window === 'undefined' ? '' : window.location.pathname;
  if (pathname !== '/') {
    pathname = pathname.replace(/\/$/, '');
  }
  const handleManagerRole = (profile: User, mySubHomeCredit: MySub) => {
    if (typeof window === 'undefined') return;

    // Handle case: reached request limit
    if (profile?.reached_request_limit) {
      navigate(`${RoutePaths.SETTINGS}/?tab=${SettingTabsKey.SUBSCRIPTION}`);
      return;
    }

    // Handle case: show unsubscription
    const shouldShowUnsubscribe =
      checkDisable(profile, mySubHomeCredit) &&
      ([RoutePaths.AGENT_REPORT, RoutePaths.MY_NFT, RoutePaths.CREATE_QR_CODE, RoutePaths.DRIP_CAMPAIGNS].includes(
        pathname,
      ) ||
        ([RoutePaths.SMART_ACTIONS].includes(pathname) &&
          window.location.search.includes(SmartActionTabsKey.SMART_TRIGGERS))) &&
      !profile?.unlimited_home;

    if (shouldShowUnsubscribe) {
      dispatch({
        type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
        payload: true,
      });
      return;
    }

    if (
      (pathname.includes(RoutePaths.MANAGER) || pathname.includes(RoutePaths.MY_REPORT)) &&
      profile.user_type !== UserType.MANAGER
    ) {
      navigate(RoutePaths.NOT_FOUND);
      return;
    } else if (pathname.includes(RoutePaths.AGENT_LOOKUP) && profile.user_type !== UserType.MANAGER) {
      navigate(RoutePaths.NOT_FOUND);
      return;
    }

    if (profile?.user_type === UserType.MANAGER && !profile?.on_boarding_complete) {
      navigate(RoutePaths.SETUP_MANAGER);
      return;
    }

    if (
      profile.user_type === UserType.MANAGER &&
      (pathname === RoutePaths.DASHBOARD ||
        pathname.includes(RoutePaths.SETUP_MANAGER) ||
        pathname.includes(RoutePaths.ON_BOARDING) ||
        pathname === RoutePaths.MAILING_LISTS ||
        pathname === RoutePaths.MAILING_LISTS_CREATE)
    ) {
      navigate(RoutePaths.MANAGER);
      return;
    }

    if (
      (pathname.includes(RoutePaths.SETUP_MANAGER) || pathname.includes(RoutePaths.CREATE_MAILING_MANAGER)) &&
      profile.user_type !== UserType.MANAGER
    ) {
      navigate(RoutePaths.AGENT_REPORT);
      return;
    }

    if (
      (pathname === RoutePaths.DASHBOARD && profile.user_type === UserType.AGENT) ||
      (pathname === RoutePaths.BROKER_MY_AGENTS && profile.user_type !== UserType.BROKER)
    ) {
      navigate(RoutePaths.MAILING_LISTS);
      return;
    }

    if (
      (pathname === RoutePaths.DASHBOARD ||
        ![RoutePaths.BROKER_MY_AGENTS, RoutePaths.SETTINGS, RoutePaths.LOGOUT].includes(pathname)) &&
      profile.user_type === UserType.BROKER
    ) {
      navigate(RoutePaths.BROKER_MY_AGENTS);
      return;
    }
  };

  const getSessionVariables = async () => {
    try {
      const sessionVars = await CampaignService.sessionsAndVars();
      return sessionVars;
    } catch (error) {
      return null;
    }
  };

  const getUserSettings = async (myProfile) => {
    try {
      const [
        creditSetting,
        myCredit,
        mySubHomeCredit,
        mySettingHomeCredit,
        // myConstant,
        campaignType,
        postgridSessionVariables,
      ] = await Promise.all([
        getCachedPromise(KEY_CACHED_DATA.CREDIT_SETTING, CreditService.getCreditSetting),
        CreditService.getMyCredit(),
        getMySub(),
        getCachedPromise(
          KEY_CACHED_DATA.MY_SUB_SETTING,
          CreditService.subSetting,
          myProfile?.user_type === UserType.MANAGER,
        ),
        // getCachedPromise(KEY_CACHED_DATA.USER_ASSETS, constantValue),
        campaignTypeList(),
        getSessionVariables(),
      ]);
      const currentSettings = mySubHomeCredit?.data?.subscription_package_details || null;

      dispatch({
        type: HomeLimitAction.SET_MY_SETTING_HOME_CREDIT_LIST,
        payload: mySettingHomeCredit?.results,
      });

      dispatch({
        type: HomeLimitAction.SET_MY_SETTING_HOME_CREDIT,
        payload: currentSettings,
      });
      dispatch({
        type: CreditAction.SET_CREDIT_SETTING,
        payload: creditSetting,
      });
      dispatch({
        type: CreditAction.SET_MY_CREDIT,
        payload: myCredit,
      });
      // dispatch({
      //   type: UserAction.USER_ASSETS,
      //   payload: myConstant,
      // });
      dispatch({
        type: AppAction.CAMPAIGN_TYPE_LIST,
        payload: campaignType,
      });

      if (postgridSessionVariables) {
        const defaultImages = await generateDefaultImageForVariables(getAllImageVariables(postgridSessionVariables));
        dispatch({
          type: CampaignAction.IMAGE_VARIABLE_DEFAULT_IMAGE,
          payload: defaultImages,
        });
        dispatch({
          type: CampaignAction.POSTGRID_SESSION_VAR,
          payload: postgridSessionVariables,
        });
        dispatch({
          type: CampaignAction.ALL_POSTGRID_SESSION_VAR,
          payload: ToolsService.getAllVariablesFromSectionVars(postgridSessionVariables),
        });
      }
      myProfile && mySubHomeCredit?.data && handleManagerRole(myProfile, mySubHomeCredit?.data);
    } catch (error: any) {
      setIsLoading(false);
      forceLogout();
    } finally {
      setIsLoading(false);
    }
  };

  const getAgentInfoOfLoggedInAccount = async () => {
    try {
      const agentData = await OverviewService.getAgentInfo();
      dispatch({
        type: HomeAction.UPDATE_USER_AGENT,
        payload: agentData || {},
      });

      const isMyReport = window.location.pathname.includes(RoutePaths.MY_REPORT);

      if (isMyReport) {
        dispatch({
          type: HomeAction.UPDATE_USER_AGENT_SSID,
          payload: agentData || {},
        });
      }
    } catch (error) {
      dispatch({
        type: HomeAction.UPDATE_USER_AGENT,
        payload: {},
      });
    }
  };
  const getAgentInfoViaSSID = async () => {
    const isMyReport = window.location.pathname.includes(RoutePaths.MY_REPORT);
    if (!ssid || isMyReport) return;
    const agentData = await OverviewService.getAgentInfo(ssid);
    dispatch({
      type: HomeAction.UPDATE_USER_AGENT_SSID,
      payload: agentData || {},
    });
  };

  const getAgentMailingListCombined = () => {
    const agentId = ssid;
    if (
      [RoutePaths.MY_REPORT, RoutePaths.MANAGER, RoutePaths.AGENT_REPORT_DYNAMIC, RoutePaths.AGENT_REPORT].some((el) =>
        pathname.includes(el),
      )
    ) {
      dispatch({
        type: MailingAction.LIST_MAILING_LIST_COMBINE,
        payload: { ssid: pathname?.includes(RoutePaths.MY_REPORT) ? undefined : agentId, page: 1 },
      });
    }
  };

  const getAgentListOfManager = async (value) => {
    const fetchList = async (value) => {
      let page = 1;
      const allResults: SlipStreamAgent[] = [];
      let continueLoop = true;
      try {
        while (continueLoop) {
          const data: Pagination<SlipStreamAgent> = await OverviewService.listUserAgent(page, limit_agent, value);
          if (data.results) {
            allResults.push(...data.results);
          }
          if (data.links && data.links.next) {
            page++;
          } else {
            continueLoop = false;
          }
        }
        return allResults ?? [];
      } catch (error) {
        return [];
      }
    };
    try {
      setIsLoadingAgent(true);
      const allResults = await fetchList(value);
      setListAgent(allResults);
    } catch (error: any) {
      // Do nothing
    } finally {
      setIsLoadingAgent(false);
    }
  };

  const fetchInitialData = async (profile) => {
    const { user_type } = profile || {};
    const isManager = user_type === UserType.MANAGER;
    const isBroker = user_type === UserType.BROKER;

    if (isManager) {
      if (
        pathname === RoutePaths.MAILING_LISTS ||
        pathname === RoutePaths.MAILING_LISTS_CREATE ||
        [RoutePaths.DRIP_CAMPAIGNS, RoutePaths.SMART_ACTIONS, RoutePaths.CREATE_QR_CODE, RoutePaths.MY_NFT]?.some(
          (el) => pathname?.includes(el),
        )
      )
        navigate(RoutePaths.MANAGER);
    }
    if ([RoutePaths.SETUP_MANAGER, RoutePaths.ON_BOARDING].some((el) => pathname.includes(el))) return;

    if (!isBroker) {
      getAgentInfoOfLoggedInAccount();
      getAgentInfoViaSSID();
      getAgentMailingListCombined();

      if (isManager) getAgentListOfManager('');
    }
  };

  const checkAccountPermission = async () => {
    const token = localStorage.getItem(StorageKeys.SESSION_KEY);
    if (!token) {
      forceLogout();
    } else {
      const myProfile = await userProfile();
      dispatch({
        type: AuthAction.RUN_AUTHENTICATED_FLOW,
        payload: myProfile,
      });
      await getUserSettings(myProfile);
      await fetchInitialData(myProfile);
    }
  };

  const getListTags = async () => {
    try {
      const res = await MailingService.listTags(FilterTagTypeEnum.ALL);
      if (res && res.length > 0) {
        dispatch({
          type: CampaignAction.LIST_TAGS,
          payload: res,
        });
      }
    } catch (error) {
      // Do nothing
    }
  };

  useEffect(() => {
    getListTags();
    checkAccountPermission();
    if (!isEmpty(cachedData)) {
      setIsLoading(false);
    }
    return () => {
      MySwal.close();
    };
  }, []);

  const isAgent = cachedData?.user_type === UserType.AGENT;

  return (
    <React.Fragment>
      {!isLoading && ((isAgent && cachedData && cachedAgent) || (!isAgent && cachedData)) ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <LoadingPage isLoading={true} />
      )}
    </React.Fragment>
  );
};

export default PrivateProvider;
